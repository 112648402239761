html {
    font-size: 62.5%;
}

body {
    // font-size: 62.5%;
    font-family: "Source Sans Pro", sans-serif;
    color: white;
    background-color: #ffffff;
    overflow: hidden;
}

// *:not(html) {
//     -webkit-transform: translate3d(0, 0, 0);
// 	transform: translate3d(0, 0, 0);
// }

.container {
    max-width: 1140px;
    margin: 0 auto;
    line-height: 150%;
    font-size: 16px;
    padding: 0 20px;

    .main-content {
        position: relative;
        z-index: 1;
    }

    a {
        color: $color-main;
        position: relative;
        cursor: pointer;
        &:hover {
            opacity: 0.7;
        }
    }
    h1 {
        font-size: 3.6rem;
        line-height: 1em;
        word-break: break-word;
    }
    h2,
    h3 {
        word-break: break-word;
    }
    blockquote {
        background: #eee;
        margin: 0;
        padding: 10px 30px;
        font-size: 120%;
        font-style: italic;
        line-height: 150%;
        margin: 20px 0px;
    }
    table {
        text-align: left;
        thead {
            background: #eee;
        }
        td,
        th {
            padding: 0;
            padding: 10px;
        }
    }
    img,
    video {
        max-width: 100%;
        height: auto;
    }
    .alignnone {
        margin: 5px 20px 20px 0;
    }

    .aligncenter,
    div.aligncenter {
        display: block;
        margin: 5px auto 5px auto;
    }

    .alignright {
        float: right;
        margin: 5px 0 20px 20px;
    }

    .alignleft {
        float: left;
        margin: 5px 20px 20px 0;
    }

    a img.alignright {
        float: right;
        margin: 5px 0 20px 20px;
    }

    a img.alignnone {
        margin: 5px 20px 20px 0;
    }

    a img.alignleft {
        float: left;
        margin: 5px 20px 20px 0;
    }

    a img.aligncenter {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .wp-caption {
        background: #fff;
        border: 1px solid #f0f0f0;
        max-width: 96%; /* Image does not overflow the content area */
        padding: 5px 3px 10px;
        text-align: center;
    }

    .wp-caption.alignnone {
        margin: 5px 20px 20px 0;
    }

    .wp-caption.alignleft {
        margin: 5px 20px 20px 0;
    }

    .wp-caption.alignright {
        margin: 5px 0 20px 20px;
    }

    .wp-caption img {
        border: 0 none;
        height: auto;
        margin: 0;
        max-width: 98.5%;
        padding: 0;
        width: auto;
    }

    .wp-caption p.wp-caption-text {
        font-size: 11px;
        line-height: 17px;
        margin: 0;
        padding: 0 4px 5px;
    }

    /* Text meant only for screen readers. */
    .screen-reader-text {
        border: 0;
        clip: rect(1px, 1px, 1px, 1px);
        clip-path: inset(50%);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute !important;
        width: 1px;
        word-wrap: normal !important; /* Many screen reader and browser combinations announce broken words as they would appear visually. */
    }

    .screen-reader-text:focus {
        background-color: #eee;
        clip: auto !important;
        clip-path: none;
        color: #444;
        display: block;
        font-size: 1em;
        height: auto;
        left: 5px;
        line-height: normal;
        padding: 15px 23px 14px;
        text-decoration: none;
        top: 5px;
        width: auto;
        z-index: 100000;
        /* Above WP toolbar. */
    }

    ul {
        padding-left: 24px;

        li {
            ul {
                padding-top: 10px;
            }
        }
    }

    ul {
        list-style: none;
    }
}

a.primary-btn {
    background: $color-main;
    border-radius: 15px;
    font-weight: 700;
    padding: 15px 40px;
    display: inline-block;
    color: white;
    text-decoration: none;
    box-shadow: 0 10px 15px 5px rgba(0, 0, 0, 0.2);
    padding: 15px 40px;
    margin-bottom: 30px;
}

.is-hidden {
    display: none !important;
}

@keyframes spin {
    to {
        @include transform(rotate(360deg));
    }
}

@keyframes spin {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

.is-loading {
    cursor: wait !important;
    color: transparent !important;

    &::before {
        content: "";
        box-sizing: border-box;
        position: absolute;
        height: 20px;
        width: 20px;
        background: transparent;
        border-radius: 50%;
        border: 3px solid rgba(0, 0, 0, 0.5);
        border-bottom: 3px solid #fdfdfd;
        animation: spin 1.5s linear infinite;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}


a.primary-btn,
button {
    position: relative;
    overflow: hidden;
}

a.primary-btn.is-disabled,
button.is-disabled {
    cursor: not-allowed;
    
    &::after {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background: #ffffff70;
    }
}

a.primary-btn.is-loading,
button.is-loading {
    position: relative;
    overflow: hidden;

    &::after {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background: #ffffff70;
    }
}

.page-padding {
    padding-top: 80px;

    @include media-query("900px") {
        padding-top: 90px;
    }
}

/* Let's get this party started */
::-webkit-scrollbar {
    width: 6px;
}

::-moz-scrollbar {
    width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background: rgba(235, 98, 0, 0.3);
    background: #e0e0e0;
}

::-moz-scrollbar-track {
    background: rgba(235, 98, 0, 0.3);
    background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
}
::-moz-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(0, 0, 0, 0.2);
}
::-moz-scrollbar-thumb:window-inactive {
    background: rgba(0, 0, 0, 0.2);
}

// @media screen and (orientation: portrait) {
// 	.potrait-warning {
// 	    display: block;
// 	}
// }
