.error-page {
    height: 100vh;
    display: flex;
    align-items: center;
    padding-bottom: 15vh;
    padding-top: 100px;

    .main-content {
        text-align: center;
        max-width: 500px;
        margin: auto;
    }

    a {
        margin-top: 20px;
        color: inherit;
    }
}

.error-page.error-404,
.error-page.error-global {
    padding-top: 100px;
    padding-bottom: 100px;

    .main-content .title-section h1 {
        position: relative;
        z-index: 1;
        margin-bottom: 40px;

        &::before {
            content: "404";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            transform: translate(-50%, -70%);
            font-size: 5em;
            font-family: inherit;
            pointer-events: none;
            z-index: -1;
            opacity: 0.04;
        }
    }
}

// 'Error' text behind the title
.error-page.error-global .main-content .title-section h1::before {
    content: "Oops!";
    font-size: 3.6em;
}

// Icon above the Maintenance title
.error-maintenance .main-content::before {
    content: "";
    display: block;
    margin: auto;
    margin-bottom: 40px;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    // background-color: #ffffff20;
    background-image: url("../images/processing-white.svg");
    background-size: contain; // 60% auto;
    background-position: center;
    background-repeat: no-repeat;
}