.decoration {

	&:after {
		content: '';
		background-image: url('../images/deco-shape.svg');
		width: 100%;
		height: 25%;
		position: absolute;
		top: 2%;
		right: 1%;
		background-size: contain;
		background-repeat: no-repeat;
		background-position-x: right;
	}

	&:before {
		content: '';
		background-image: url('../images/deco-lines.svg');
		width: 100%;
		height: 40%;
		position: absolute;
		bottom: 0;
		left: 0;
		background-size: contain;
		background-repeat: no-repeat;
		background-position-x: left;
		z-index: 0;
	}
}
