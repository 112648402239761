.level-score {

	max-width: none;
	overflow-y: auto;
    max-height: 100vh;

	.main-content {
		max-width: 700px;
		margin: 0 auto;
		padding-top: 60px;

		@include media-query('900px'){
		    padding-top: 90px;
		};
	}

	.person-section {
		width: 100%;
		margin-bottom: 20px;

		.avatar {
			width:80px;
			height: 80px;
			border-radius: 100px;
			border: 4px solid white;
			display: block;
			margin: 0 auto;
			background-size: cover;
			background-position: center center;
			margin-bottom: 15px;
		}

		.bubble {
			padding: 20px;
			border-radius: 40px;
			background: white;
			width: 100%;
			position: relative;
			display: block;
			margin: 0 auto;
			color: $color-text;
			text-align: center;

			&::after {
				content: "";
				display: inline-block;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 7.5px 10px 7.5px;
				border-color: transparent transparent #ffffff transparent;
				position: absolute;
				top: -8px;
				@extend .center-horizontal;
			}

			h1 {
				color: $color-text-secondary;
				margin: 0;
			    margin-bottom: 10px;
				@include media-query('phone-wide'){
				    font-size: 2.4rem;
				};
			}

			p {
				margin: 0;
			}
		}
	}

	.score-section {
		@include clearfix;
		border: 1px solid rgba(255,255,255,0.2);
		border-radius: 40px;
		position: relative;
		margin-bottom: 30px;

		&:before {
			content: '';
			position: absolute;
			@extend .center-middle;
			height: 90%;
			width: 1px;
			border-right: 1px dashed rgba(255,255,255,0.2);

			@include media-query('tablet-small'){
			    display: none;
			};
		}

		.score-list-container {
			float: left;
			width: 50%;
			padding: 20px 10px;

			@include media-query('tablet-small'){
			    width: 100%;
				position: relative;
			    top: 350px;
			};

			ul.score-list {
				width: 100%;
				padding-left: 0;
			    font-size: 1.6rem;
				margin: 0;
				position: relative;

				li.score-item {
					display: block;
					margin-bottom: 20px;
					position: relative;
					width: 100%;
					padding: 0 20px;
				    padding-left: 50px;

					img {
						$local-size: 1.4rem;
						position: absolute;
						top: 5px;
						left: 20px;
						height: $local-size;
						width: $local-size;
						object-fit: contain;
						object-position: center;

						/* Font Awesome
						color: $color-main;
						margin-right: 12px;
						font-size: 1.4rem;
						*/
					}

					span {
						position: absolute;
						right: 20px;
					}

					&:last-child {
						margin-bottom: 0;
					}

					@include media-query-height('720px'){
					    margin-bottom: 15px;
					};
				}
			}
		}
	}

	.score-detail-container {
		float: left;
		width: 50%;
		padding: 10px 20px;

		@include media-query('tablet-small'){
			width: 100%;
			position: relative;
			top: -380px;
		};

		@include media-query-height('720px'){
		    top: -350px;
		};

		.level-title {
			text-align: center;
			margin: 40px 0;
			margin-bottom: 50px;

			.icon {
				$local-size: 6rem;
				$local-img-size: $local-size * 50 / 100;
				position: relative;
				height: $local-size;
				width: $local-size;
				margin: auto;
				margin-bottom: 10px;
				border-radius: 50%;
				background-color: #ffffff;

				img {
					@extend %set-img-icon;
					@extend %set-absolute-center;
					height: $local-img-size;
					width: $local-img-size;
				}
			}

			p {
				font-size: 1.8rem;
				font-weight: 700;
			}
		}

		.score-detail {
			@include clearfix;
			margin-bottom: 40px;

			.your-score,
			.average-score {
				float: left;
				width: 50%;
				text-align: center;

				h2{
					margin: 0;
					font-weight: 700;
				}
				p {
					margin: 0;
					font-size: 1.4rem;
					position: relative;
					color: rgba(255,255,255,0.5);

					span {
						width: 12px;
						height: 12px;
						display: inline-block;
						top: 7px;
						background: black;
						position: absolute;
						left: 15px;
					}
				}
			}

			.your-score {
				padding-left: 20px;
				h2 {
					font-size: 4.5rem;
					position: relative;
				    bottom: 8px;
				}
				p span {
					background: $color-main;
				}
			}

			.average-score {
				padding-right: 20px;
				p span {
					background: #70CEF5;
					left: 7px;
				}
			}
		}

		.bar-container {
			width: 100%;
		    border-left: 2px solid white;

			.bar-item {
				width: 100%;
				height: 15px;
				background: rgba(0,0,0,0.5);
				position: relative;

				.bar-inner {
					position: absolute;
					left: 0;
					top: 0;
					bottom: 0;
					background: black;
				}

				span {
					position: absolute;
					font-size: 1.2rem;
					right: -17px;
					top: -4;
					line-height: 14px;
				}

				&.more90 {
					span {
						right: 5px;
					}
				}

				&.your-score {
					background: rgba(83,194,38,0.5);
					.bar-inner {
						background: rgba(83,194,38,1);
					}
				}

				&.average-score {
					background: rgba(112,206,245,0.5);
					.bar-inner {
						background: rgba(112,206,245,1);
					}
				}
			}
		}

		.info-section {
			@extend %set-flex-center;

			.icon {
				$local-size: 2rem;
				$local-img-size: $local-size * 90 / 100;
				position: relative;
				height: $local-size;
				width: $local-size;
				margin-right: 15px;
				border-radius: 50%;
				background-color: #086CBB;

				img {
					@extend %set-img-icon;
					@extend %set-absolute-center;
					height: $local-img-size;
					width: $local-img-size;
				}
			}

			p {
				color: rgba(255,255,255,0.5);
			}
		}
	}

	.button-section {
		text-align: center;
		margin-bottom: 20px;
	}
}
