.site-menu {
    $local-size: 60px;
    $local-img-size: $local-size * 50 / 100;
    $local-size-mobile: 51px;
    $local-img-size-mobile: $local-size-mobile * 50 / 100;
    position: relative;
    float: right;
    width: $local-size;
    height: $local-size;
    border: 2px solid white;
    border-radius: 30%;

    
    @include media-query("900px") {
        width: $local-size-mobile;
        height: $local-size-mobile;
    }

    @include media-query("phone") {
        width: $local-size-mobile;
        height: $local-size-mobile;
    }

    @include media-query-height("wp-phone") {
        width: $local-size-mobile;
        height: $local-size-mobile;
    }

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        height: $local-img-size;
        width: $local-img-size;
        transform: translate(-50%, -50%);
    
        @include media-query("900px") {
            width: $local-img-size-mobile;
            height: $local-img-size-mobile;
        }
        
        @include media-query("phone") {
            width: $local-img-size-mobile;
            height: $local-img-size-mobile;
        }

        @include media-query-height("wp-phone") {
            width: $local-img-size-mobile;
            height: $local-img-size-mobile;
        }
    }
}