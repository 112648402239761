.page-background.bg-login {
	position: fixed;
	height: 100vh;

	.overlay-blue {
		opacity: 1;
	}
}

.container.login-page {
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	height: 100vh;
	max-width: none;

	.main-content {
		min-height: 85vh;
		max-width: 500px;
		margin: 0 auto;
		overflow-x: hidden;
	    // margin-bottom: 140px;
		padding-top: 100px;
		padding-bottom: 40px;
	}

	.title-section {
		margin-bottom: 40px;
		text-align: center;

		h1 {
			font-size: 3.6rem;
			line-height: 1em;
			word-break: break-word;

			@include media-query('phone-wide'){
				font-size: 2.4rem;
			};
		}
	}

	.message-section {

		width: 100%;
		overflow: hidden;
		border-radius: 10px;
		position: relative;
		padding: 15px 20px;
		background: rgba(255,255,255,0.07);
		margin-bottom: 20px;

		p {
			margin: 0;
		}

		&:after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			width: 5px;
			background: black;
		}

		&.error {
			&::after {
				background: #E9444A;
			}
		}

		&.success {
			&::after {
				background: $color-main;
			}
		}
	}

	.form-section {
		background: white;
		width: 100%;
		padding: 30px 20px;
		border-radius: 10px;

		form {
			margin: 0;
		}

		input {
			width: 100%;
			padding: 15px;
			margin: 10px 0px;
			border-radius: 3px;
			border: transparent;
			background-color: rgba(200,200,200,0.3);
			box-shadow: inset 0px 2px 5px 0px rgba(204,204,204,1);
			border-radius: 5px;
			margin-bottom: 15px;
			font-weight: 700;
			color: $color-text;
		}

		button {
			background: $color-main;
			width: 100%;
			padding: 15px 20px;
			font-weight: 700;
			border: none;
			border-radius: 5px;
		}
	}

	.register-page {
		.names {
			@include clearfix;

			p {
				float: left;
				width: calc(50% - 10px);

				&:first-child {
					margin-right: 20px;
				}

				@include media-query('phone-wide'){
					width: 100%;
					margin-right: 0;
				};
			}
		}

		p {
			margin: 0;
			margin-bottom: 20px;
		}

		label {
			color: $color-text;
		    display: block;
		    font-weight: 700;
		}

		input {
			font-weight: 500;
			margin-bottom: 0;

			&::placeholder {
				color: $color-text-gray;
				opacity: 0.7;
			}
		}

		.select2-container {
			width: 100% !important;
			margin-top: 10px;

			.select2-selection--single {
				height: 50px;
			}
		}

		button {
			margin-top: 10px;
		}

	}
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
   .wave-container {
        display: none;
   }
}

.wave-container {
	position: relative;
	width: 100vw;
	margin: auto;
	left: 50%;
	transform: translateX(-50%);

	.waves {
		position: relative;
		width: 100%;
		height: 15vh;
		margin-bottom: -7px; /*Fix for safari gap*/
		min-height: 100px;
	}

	/* Animation */

	.parallax > use {
		animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
	}
	.parallax > use:nth-child(1) {
		animation-delay: -2s;
		-ms-animation-delay: -2s;
		-moz-animation-delay: -2s;
		-webkit-animation-delay: -2s;

		animation-duration: 7s;
		-ms-animation-duration: 7s;
		-moz-animation-duration: 7s;
		-webkit-animation-duration: 7s;
	}
	.parallax > use:nth-child(2) {
		animation-delay: -3s;
		-ms-animation-delay: -3s;
		-moz-animation-delay: -3s;
		-webkit-animation-delay: -3s;

		animation-duration: 10s;
		-ms-animation-duration: 10s;
		-moz-animation-duration: 10s;
		-webkit-animation-duration: 10s;
	}
	.parallax > use:nth-child(3) {
		animation-delay: -4s;
		-ms-animation-delay: -4s;
		-moz-animation-delay: -4s;
		-webkit-animation-delay: -4s;

		animation-duration: 13s;
		-ms-animation-duration: 13s;
		-moz-animation-duration: 13s;
		-webkit-animation-duration: 13s;
	}
	.parallax > use:nth-child(4) {
		animation-delay: -5s;
		-ms-animation-delay: -5s;
		-moz-animation-delay: -5s;
		-webkit-animation-delay: -5s;

		animation-duration: 20s;
		-ms-animation-duration: 20s;
		-moz-animation-duration: 20s;
		-webkit-animation-duration: 20s;
	}
	@keyframes move-forever {
		0% {
			transform: translate3d(-90px,0,0);
	        -ms-transform:translate3d(-90px,0,0);
	        -moz-transform:translate3d(-90px,0,0);
	        -webkit-transform:translate3d(-90px,0,0);
	        -o-transform:translate3d(-90px,0,0);
		}
		100% {
			transform: translate3d(85px,0,0);
			-ms-transform:translate3d(85px,0,0);
	        -moz-transform:translate3d(85px,0,0);
	        -webkit-transform:translate3d(85px,0,0);
	        -o-transform:translate3d(85px,0,0);
		}
	}

}


.select2-container--default .select2-selection--single {
	border-radius: 5px !important;
	border: 1px solid rgba(170,170,170,0.5) !important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
	padding: 10px 15px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
	top: 12px !important;
	right: 12px !important;
}

.select2-search--dropdown .select2-search__field {
	padding: 10px;
	background: #ececec;
	border-radius: 4px;
	color: black;
	font-size: 1.4rem;
}

.select2-container--default .select2-results>.select2-results__options {
	font-size: 1.4rem;
	color: black;
}

.select2-results__option {
	padding: 10px;
}

#back-to-login {
	display: flex;

	.icon {
		margin-right: 4px;

		img {
			@extend %set-img-icon;
			height: 1em;
			width: 1em;
			vertical-align: middle;
		}
	}
}