.overlay-blue.leaderboard-blue {
	opacity: 1;
}

.leaderboard {

	max-height: 100vh;
	overflow-y: auto;
	max-width: none;

	.main-content {
		max-width: 1140px;
	    margin: 0 auto;
		padding-top: 60px;
		position: relative;
		z-index: 9;

		@include media-query('900px'){
		    z-index: 1;
		};
	}

	.main-content-inner {
		@include clearfix;
		margin-bottom: 30px;
	}

	.section-scorecard {
		width: calc(40% - 40px);
		float: left;
		margin-right: 40px;

		@include media-query('tablet'){
		    width: 100%;
			margin-bottom: 30px;
		};

		.section-title {
			margin-bottom: 40px;
			h1 {
				margin-bottom: 10px;
			}
			p {
				margin: 0;
			}
		}

		.score-overall {
			@include clearfix;

			.score-number {
				float: left;
				width: 110px;
				text-align: center;
				border-right: 1px solid rgba(255,255,255,0.2);
				padding-right: 20px;

				h2 {
					margin: 0;
					font-size: 4.8rem;
					font-weight: 500;
				}

				p {
					margin: 0;
					margin-top: 15px;
					color: rgba(255,255,255,0.5);
				}
			}

			.score-detail {
				float: left;
				width: calc(100% - 120px);
				@include clearfix;
				margin-bottom: 30px;
				padding-left: 40px;

				.your-score,
				.average-score {
					p {
						margin: 0;
						position: relative;
						color: rgba(255,255,255,0.5);
						margin-bottom: 5px;

						span {
							width: 12px;
							height: 12px;
							display: inline-block;
							top: 7px;
							background: black;
							position: absolute;
							left: -20px;
						}
					}
				}

				.your-score {
					p span {
						background: $color-main;
					}
				}

				.average-score {
					p span {
						background: #70CEF5;
					}
				}
			}
		}

		.scorelist-container {
			a{
				text-decoration:none;
				outline:none;
			}
			.hide {
				display: none;
			}

			#accordion-menu {

				border-radius: 5px;
				-webkit-border-radius: 5px;
				-moz-border-radius: 5px;
			    border: 2px solid rgba(255,255,255,0.2);
			    overflow: hidden;

				ul {
					padding-left: 0;
					margin: 0;
				}

				li {
					position: relative;

					&:after {
						content: '';
						width: 90%;
						height: 1px;
						background: rgba(255,255,255,0.2);
						position: absolute;
						bottom: -1px;
						@extend .center-horizontal;
					}

					&:last-child {
						&:after {
							display: none;
						}
					}
				}

				a {
					@include clearfix;
				}

				.chapter-detail {
					position: relative;
					float: left;
					width: calc(60% - 10px);
					margin-right: 10px;

					.icon {
						$local-size: 3rem;
						$local-img-size: $local-size * 70 / 100;
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						height: $local-size;
						width: $local-size;
						border-radius: 50%;
						background-color: #fff;

						img {
							@extend %set-img-icon;
							@extend %set-absolute-center;
							height: $local-img-size;
							width: $local-img-size;
						}
					}

					p {
						padding-left: 40px;
						padding-right: 30px;
						position: relative;
						margin: 0;
						padding-top: 2px;
						white-space: nowrap;
					    overflow: hidden;
					    text-overflow: ellipsis;
					}

					span {
						position: absolute;
						right: 0;
					}
				}

				.chapter-bar {
					width: calc(35% - 10px);
					margin-right: 10px;
					float: left;

					.bar-container {
						width: 100%;
					    border-left: 2px solid white;

						.bar-item {
							width: 100%;
							height: 15px;
							background: rgba(0,0,0,0.5);
							position: relative;

							.bar-inner {
								position: absolute;
								left: 0;
								top: 0;
								bottom: 0;
								background: black;
							}

							span {
								position: absolute;
								font-size: 1.2rem;
								right: -17px;
								top: -4.5px;
							}

							&.more90 {
								span {
									right: 5px;
								}
							}

							&.your-score {
								background: rgba(83,194,38,0.5);
								.bar-inner {
									background: rgba(83,194,38,1);
								}
							}

							&.average-score {
								background: rgba(112,206,245,0.5);
								.bar-inner {
									background: rgba(112,206,245,1);
								}
							}
						}
					}
				}

				.plus-btn {
					width: 5%;
					float: left;

					.icon {
						@extend %set-flex-center;
						padding: 8px 0;

						img {
							@extend %set-img-icon;
							height: 1.6rem;
							width: 1.6rem;
							transition: all 0.3s ease-in-out;
						}
					}
				}

				.open {
					cursor: pointer;
					display: block;
					margin: 0px 0px 1px 0px;
					padding: 19px 15px;
					transition: all 0.3s ease-in-out;
				}

				.open-selected {
					background-color: white;

					.chapter-detail {
						i, p, span {
							color: #064D87;
						}
					}

					.plus-btn .icon img {
						@include transform(rotate(45deg));
					}
				}

				.content {
					color: $color-text-secondary;
					background-color: white;
					position: relative;
				    top: -1px;
					padding-top: 10px;

					&:before {
						position: absolute;
						content: '';
						width: 90%;
						height: 1;
						background: $color-text-secondary;
						opacity: 0.2;
						@extend .center-horizontal;
						top: 0;
					}

					.score-table {
						ul {
							padding: 0;
						}

						li {
							padding-left: 45px;
							padding-right: 30px;
							position: relative;
							padding-top: 15px;
						    padding-bottom: 15px;
						    margin-right: 42px;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;

							.icon {
								$local-size: 24px;
								$local-img-size: $local-size * 80 / 100;
								position: absolute;
								top: 50%;
								left: 14px;
								transform: translateY(-50%);
								height: $local-size;
								width: $local-size;
								border-radius: 50%;

								img {
									@extend %set-img-icon;
									@extend %set-absolute-center;
									height: $local-img-size;
									width: $local-img-size;
								}
							}

							span {
								position: absolute;
								right: 0;
							}
						}
					}

					.chapter-info {
						padding: 0 20px;
						padding-bottom: 20px;
						h2 {
							margin-bottom: 10px;
						}
						p {
							color: $color-text;
						}
						a {
							color: $color-text-secondary;
							text-decoration: none;
							margin-top: 20px;
							font-weight: 700;

							.icon {
								display: inline-block;
								margin-left: 5px;
								padding: 2px 0;
								vertical-align: middle;
			
								img {
									@extend %set-img-icon;
									height: 1em;
									width: 1em;
								}
							}
						}
					}




				}

				a {
					color: #ffffff;
				}

			}

		}
	}

	.section-highscore {
		width: 60%;
		float: left;

		@include media-query('tablet'){
		    width: 100%;
		};

		.section-title {
		    margin-bottom: 55px;
			h1 {
				margin-bottom: 10px;
			}
			p {
				margin: 0;
				color: rgba(255,255,255,0.5);
			}
			span {
				color: white;
			}
		}

		.section-filter {
			@include clearfix;
			width: 100%;
			margin-bottom: 32px;

			.filter-text {
				width: 10%;
				float: left;
				padding-top: 7px;
			}

			.filter-chapter,
			.filter-country,
			.filter-business,
			.filter-cohort {
				width: 16.5%;
				margin-right: 1%;
				float: left;
			}

			.filter-search {
				width: 20%;
				float: left;

				.search-box {
					border-radius: 3px;
					width: 100%;
					border: 1px solid rgba(255,255,255,0.2);
					font-size: 1.4rem;
					position: relative;

					input {
						width: 100%;
						background: none;
						padding: 10px;
						border: none;

						&::placeholder {
							color: rgba(255,255,255,0.5);
						}
					}

					.icon {
						$local-size: 40px;
						$local-img-size: $local-size * 50 / 100;
						position: absolute;
						width: $local-size;
						top: 0;
						bottom: 0;
						right: 0;
						border-radius: 3px;
						background: #fff;
						cursor: pointer;

						img {
							@extend %set-img-icon;
							@extend %set-absolute-center;
							height: $local-img-size;
							width: $local-img-size;
						}
					}
				}
			}

			@include media-query('phone-wide'){
			    .filter-text,
				.filter-chapter,
				.filter-country,
				.filter-business,
				.filter-cohort,
				.filter-search {
					width: 100%;
					margin-bottom: 10px;
				}
			};


			.select2-container {
				width: 100% !important;
			}

			.select2-container .select2-selection--single {
				height: auto;
				border-radius: 3px !important;
			    font-size: 1.4rem;
			}

			.select2-container--default .select2-selection--single .select2-selection__rendered {
				padding: 5px 10px;
			}

			.select2-container--default .select2-selection--single .select2-selection__arrow {
				top: 8px !important;
				right: 5px !important;
			}
		}

		.section-table {

			border-radius: 5px;
			border: 2px solid rgba(255,255,255,0.2);
		    overflow: hidden;
			padding: 5px 10px;
			margin-bottom: 10px;

			.collumn {
				width: 100%;
				@include clearfix;

				&.header {
					font-weight: 700;
					margin-bottom: 10px;
				}

				&.you {
					color: #9AF883;
				}
			}


			.cell {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				padding: 8px 5px;
			}

			.rank {
				width: 10%;
				float: left;
			}

			.name {
				width: 25%;
				float: left;
			}

			.score {
				width: 10%;
				float: left;
			}

			.business {
				width: 20%;
				float: left;
			}

			.country {
				width: 20%;
				float: left;
			}

			.cohort {
				width: 15%;
				float: left;
			}

			@include media-query('phone-wide'){
				.rank,
				.score {
					width: 20%;
				}

				.name {
					width: 60%;
				}

				.business,
				.country,
				.cohort {
					display: none;
				}
			};

			#section-leaderboard {
				position: relative;

				&.is-loading:before {

				}
			}
		}

		.section-link {
			width: 100%;
			text-align: right;

			a {
				position: relative;
				color: white;
				text-decoration: none;

				.icon {
					display: inline-block;
					margin-left: 5px;

					img {
						@extend %set-img-icon;
						height: 1.4rem;
						width: 1.4rem;
					}
				}
			}
		}
	}

	.all-highscore .section-highscore {
		width: 100%;

		.section-title {
		    text-align: center;
		}

		.section-table {
			max-height: 50%;
		    overflow-y: auto;
		}
	}



	.button-section {
		text-align: right;
		margin-bottom: 20px;

		@include media-query('phone-wide'){
			text-align: center;
		};
		a {
			box-shadow: none;
		}
	}

	#popup-survey {
		display: flex;
		align-items: center;

		.content {
			height: auto;
			max-height: 100%;
			width: 100%;
			max-width: none;
			padding: 20px;
			overflow: auto;
		}

		.content-inner {
			position: relative;
			top: 0;
			transform: translate(0,0);
			max-height: none;
			width: 500px;
			max-width: 100%;
			margin-left: auto;
			margin-right: auto;
			border-radius: 40px;
			color: $color-text;

			.popup-title {
				font-size: 1.6rem;
			}

			.survey-item {
				margin-top: 30px;
			}

			.popup-survey-text {
				padding: 0 60px;
				@include media-query('phone-wide') {
					padding: 0 0px;
				}
				@include media-query('phone') {
					text-align: left;
				}

				&.small {
					margin-bottom: 0;
					font-size: .96em;
					color: $color-text-gray;
				}
			}

			.survey-knowledge-stars {
				display: flex;
				width: fit-content;
				margin: auto;
				justify-content: center;
				font-size: 3.2rem;
				color: $color--background-button;

				@include media-query('phone-wide'){
					font-size: 2.8rem;
				}

				button {
					margin: 0;
					padding: 0 6px;
					outline: none;
					border: none;
					background: transparent;
				}

				button svg {
					height: 34px;
					width: 34px;
				}

				&:not(.selected):hover,
				&:not(.selected):focus-within {
					button {
						color: #f1c433;
					}
				}

				&:not(.selected) button:hover ~ button,
				&:not(.selected) button:focus ~ button {
					color: $color--background-button;
				}

				button.selected {
					color: #f1c433;
				}
			}

			.survey-recommend-slide {
				position: relative;
				height: 2px;
				width: calc(100% - 60px);
				margin: 50px 30px;
				border: 0;
				border-radius: 10px;
				background: #e4e7ec;

				@include media-query("wp-phone"){
					width: calc(100% - 40px);
					margin-left: 20px;
					margin-right: 20px;
				}
			
				.slide-handle {
					position: absolute;
					top: 50%;
					left: 0%;
					transform: translate(-50%, -50%);
					display: block;
					width: 12px;
					height: 12px;
					margin: 0;
					box-sizing: content-box;
					border-radius: 50%;
					border: 13px solid #1e5796;
					background: #56c23c;
					box-shadow: 0 4px 16px 0px rgba(0, 0, 0, 0.1);
					outline: none;
					cursor: pointer;
				}

				.slide-label {
					position: absolute;
					top: -4.4rem;
					left: 0%;
					transform: translateX(-50%);
					font-size: .86em;
				}

				.slide-progress {
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;
					width: 0%;
					border-radius: 10px;
					background: #56c23c;
				}
			}

			.survey-feedback-val {
				width: 100%;
				height: 150px;
				padding: 20px;
				outline: none;
				border: none;
				border-radius: 20px;
				color: $color-text;
				background: #f2f4f6;
				resize: none;

				&::placeholder {
					color: #b2b3b3;
				}

				&::-webkit-input-placeholder {
					color: #b2b3b3;
				}

				&::-moz-placeholder {
					color: #b2b3b3;
				}

				&:-ms-input-placeholder {
					color: #b2b3b3;
				}
			}

			.button-section {
				margin-bottom: 0;
			}
		}
	}
}
