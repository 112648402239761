.info-page {

	max-height: 100vh;
	overflow-y: auto;

	.section-text {
		margin-bottom: 40px;
	}

	.section-button {
		margin-bottom: 20px;
	}
}
