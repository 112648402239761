.question-container {
	width: calc(100% - 300px);
	max-width: none;
	margin-left: 300px;
	padding-top: 20px;
	max-height: 100vh;
	overflow: auto;

	.main-content {
		overflow: hidden;
	}

	@include media-query('tablet-wide'){
		width: 75%;
		margin-left: 25%;
	};

	@include media-query('900px'){
		width: 100%;
		margin-left: 0;
		padding-top: 90px;
	};

	@include media-query-height('wp-phone'){
		width: 100%;
		margin-left: 0;
		padding-top: 90px;
	};
}

.question-page {

	padding: 20px 30px;

	@include media-query('900px'){
		padding: 20px;
		padding-top: 70px;
	};

	@include media-query-height('wp-phone'){
		padding: 20px;
		padding-top: 80px;
	};

	.top-bar {
		@include clearfix;
		margin-bottom: 40px;

		@include media-query('900px'){
			margin-bottom: 10px;
		};

		.title-detail {
			float: left;
			@include clearfix;
			margin-top: 20px;

			@include media-query('tablet-wide'){
				width: 100%;
				margin-bottom: 10px;
			};

			.avatar-container {
				float: left;
				display: inline-block;
				margin-right: 15px;
			}

			.avatar {
				width: 50px;
				height: 50px;
				border-radius: 100px;
				border: 2px solid white;
				background-size: cover;
				background-position: center center;
				position: relative;

				span.notif {
					width: 25px;
					height: 25px;
					border-radius: 100px;
					background-color: $red;
					position: absolute;
					top: -10px;
					right: -10px;
					font-size: 1.4rem;
					text-align: center;
					display: none;

					&:before {
						content: "";
						width: 40px;
						height: 40px;
						border-radius: 100px;
						background: $red;
						position: absolute;
						@extend .center-middle;
						opacity: 0.3;
					}
				}
			}

			.title-container {
				float: left;
				display: inline-block;

				h2 {
					margin: 0;
					font-weight: 700;
					font-size: 1.8rem;
				}

				p {
					font-size: 1.4rem;
					opacity: 0.5;
					margin: 0;
				}
			}
		}

		.business-score-container {
			float: right;
			width: 60%;
			padding: 10px;
			border: 2px solid rgba(255,255,255,0.1);
			border-radius: 20px;
			padding-top: 5px;

			@include media-query('tablet-wide'){
				width: 100%;
			};

			@include media-query('900px'){
				display: none;
			};

			.business-title {
				margin-bottom: 10px;
				p {
					font-size: 1.4rem;
					margin: 0;
				}
				
				a {
					$local-size: 20px;
					$local-img-size: $local-size * 80 / 100;
					width: $local-size;
					height: $local-size;
					border-radius: 50%;
					background: rgba(255,255,255,0.1);
					display: inline-block;
					position: relative;
					top: 5px;
					margin-left: 5px;
					font-size: 1rem;
					color: white;

					img {
						position: absolute;
						height: $local-img-size;
						width: $local-img-size;
						@extend .center-middle;
					}
				}
			}

			.scores {
				@include clearfix;
				width: 100%;
			}

			.score-item {
				float: left;
				@include clearfix;
				width: 19%;
				margin-right: 1%;

				&:last-child {
					margin-right: 0;
				}

				.icon {
					float: left;
					display: flex;
					width: 26px;
					height: 26px;
					border-radius: 100px;
					position: relative;
					background: rgba(255,255,255,0.1);
					margin-right: 10px;
					justify-content: center;
					align-items: center;

					img {
						height: 1.6rem;
						width: 1.6rem;
						object-fit: contain;
						object-position: center;
					}
				}
			}

			.score-detail {
				float: left;
				width: calc(100% - 40px);

				p {
					margin: 0;
					font-size: 1.2rem;
					text-overflow: ellipsis;
				    overflow: hidden;
				    white-space: nowrap;
				}

				.score-bar {
					height: 3px;
					width: 100%;
					border-radius: 50px;
					background: #003967;
					position: relative;

					.score-bar-inner {
						height: 3px;
						position: absolute;
						left: 0;
						border-radius: 20px;

						&.green {
							background-color: $color-main;
						}

						&.orange {
							background-color: #E4A03B;
						}

						&.red {
							background-color: $red;
						}
					}
				}
			}
		}
	}

	.question-timeline {
		position: relative;
		margin-bottom: 30px;

		.line {
			width: 98%;
			height: 4px;
			background: #003967;
			border-radius: 10px;
			position: absolute;
			top: 10px;
		}

		.line-inner {
			background: $color-main;
			height: 4px;
			position: absolute;
			left: 0;
			border-radius: 10px;
		}

		.question-icons {
			@include clearfix;
			width: calc(100% + 11%);

			@include media-query('desktop'){
				width: calc(100% + 10%);
			};

			@include media-query('720px'){
				width: calc(100% + 8%);
			};

			@include media-query('phablet'){
				width: calc(100% + 6%);
			};

			@include media-query('phone'){
				width: calc(100% + 4%);
			};

			.question-item {
				width: 12.5%;
				float: left;

				&.active {
					.question-type {
						visibility: visible;
						opacity: 1;
					}
				}

				&:first-child {
					span.question-type {
						left: 0;
						transform: translateX(0);
						&::after {
							left: 12px;
						}
					}
				}

				&:last-child {
					span.question-type {
						left: auto;
					    right: 0;
						transform: translateX(0px);
						&::after {
							left: auto;
						    right: -2px;
						}
					}
				}

				.icon {
					width: 25px;
					height: 25px;
					background-color: white;
					border-radius: 10px;
					position: relative;
				}

				i {
					position: absolute;
					@extend .center-middle;
					color: #064D87;
				}

				span.question-type {
					visibility: hidden;
					opacity: 0;
					position: absolute;
				    background: white;
				    color: #4B4B4B;
				    top: 35px;
				    border-radius: 15px;
					padding: 0px 8px;
					 font-size: 1.2rem;
				    font-weight: 700;
				    text-align: left;
					@extend .center-horizontal;

					&::after {
						content: "";
					    display: inline-block;
					    width: 0;
					    height: 0;
					    border-style: solid;
					    border-width: 0 7.5px 10px 7.5px;
					    border-color: transparent transparent #ffffff transparent;
					    position: absolute;
					    top: -5px;
						@extend .center-horizontal;
					}
				}
			}
		}
	}

	.question-section {

		&.question-knowledge {
			margin-top: 60px;
		}

		/* Tabs */
		.tabs {
			width: 100%;
		}
		ul#tabs-nav {
			list-style: none;
			margin: 0;
			overflow: hidden;
			position: relative;
			padding-left: 0;
			margin-bottom: 30px;

			&:before {
				content: '';
				position: absolute;
				width: 100%;
				height: 2px;
				background: rgba(255,255,255,0.1);
				bottom: 0;
				left: 0;
			}
		}
		ul#tabs-nav li {
			float: left;
			margin-right: 2px;
			padding: 15px 0;
			cursor: pointer;
			margin-right: 30px;

			a {
				opacity: 0.5;
			}


			.alternative-tab {
				display: inline-block;
				position: absolute;
				right: 0;
				top: 15px;
				opacity: 1;

				i {
					font-size: 1.4rem;
					color: $color-main;
					margin: 0 5px;
				}
			}
		}
		ul#tabs-nav li:hover,
		ul#tabs-nav li.active {
			border-bottom: 2px solid white;

			a {
				opacity: 1;
			}

		}

		ul#tabs-nav li.active {
			.alternative-tab {
				display: none;
			}
		}

		#tabs-nav li a {
			text-decoration: none;
			color: #FFF;
		}
		.tab-content {

			font-size: 1.8rem;

			.tab-trigger {
				text-decoration: none;
				color: white;
				display: none;
				margin-top: 40px;
				margin-bottom: 20px;

				@include media-query('phone-wide'){
					display: block;
				};

				i {
					font-size: 1.4rem;
					color: $color-main;
					margin: 0 5px;
				}
			}
		}

		.question-box {
			@include clearfix;

			.question-collumn{
				float: left;
				width: 100%;
				margin-right: 0%;
				margin-bottom: 30px;
				font-size: 1.8rem;

				// @include media-query('720px'){
				//     width: 100%;
				// 	margin-right: 0;
				// };
			}

			.answer-collumn {
				float: left;
				width: 100%;
				font-size: 1.6rem;

				// @include media-query('720px'){
				//     width: 100%;
				// };

				.answers {
					width: 100%;
					margin-bottom: 30px;
					display: flex;
				    flex-wrap: wrap;
				}

				.answer-item {
					position: relative;
					height: auto;
					width: 50%;
					padding: 8px;

					@include media-query('phone-wide'){
					    width: 100%;
					};

					input {
						position: absolute;
						pointer-events: none;
						visibility: hidden;
					}

					input:focus + label {
						background: #eeeeff;

						.checker {
							border-color: #6666ff;
						}
					}

					input:checked + label {

						color: rgba(255,255,255,1);

						.checker {
							box-shadow: inset 0 0 0 6px $color-main;
						}
					}

					label {
						border-radius: 20px;
					    padding: 20px 15px;
						cursor: pointer;
						transition: background-color .3s ease;
						background: #1F56A3;
						box-shadow: 0 5px 10px rgba(0,0,0,0.08);
						display: block;
						padding-left: 60px;
						color: rgba(255,255,255,0.7);
						height: 100%;

						@include media-query('phone-wide'){
							padding-top: 10px;
						    padding-bottom: 10px;
						};

						@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
						   height: auto;
						}

						&:hover {
							background: #3268B4;

							.checker {
								box-shadow: inset 0 0 0 2px $color-main;
							}
						}

						p {
							margin: 0;
						}


					}

					.checker {
						width: 18px;
						height: 18px;
						border-radius: 50%;
						box-shadow: inset 0 0 0 2px rgba(255,255,255,0.5);
						transition: box-shadow .3s ease;
						position: absolute;
						left: 20px;
						top: 25px;

						@include media-query('phone-wide'){
							top: 15px;
						};
					}

					img {
						max-height: 150px;
					    border-radius: 5px;
					}
				}

				.button-section {
					@include clearfix;

					.tab-trigger {
						display: none;
					    float: left;
					    margin-top: 20px;

						@include media-query('phone-wide'){
							display: inline-block;
						};
					}

					.primary-btn {
						float: right;
						box-shadow: none;
					}
				}
			}
		}
	}

	.double-content {
		.question-knowledge {
			display: block;
		}
		.question-dillema {
			display: none;
		}

		@include media-query('phone-wide'){
			.question-knowledge {
				display: none;
			}
			.question-dillema {
				display: block;
			}
		};
	}

	.tab-trigger {
		.icon {
			display: inline-block;
			margin: 0 4px;
			vertical-align: middle;

			img {
				@extend %set-img-icon;
				height: 1em;
				width: 1em;
			}
		}
	}
}
