// COLORS

$color--background-body: #EFEFEF;
$color--background-secondary: #f5f5f5;
$color--background-screen: #f1f1f1;
$color--background-hr: #ccc;
$color--background-button: #e6e6e6;
$color--background-pre: #eee;
$color--background-ins: #fff9c0;
$color--background-main: #eb6200;

$color-main: #56C23C;
$color-text: #4B4B4B;
$color-text-secondary: #086CBB;
$color-text-gray: #9D9D9D;

$red: #E9444A;
