.conditional-video {

	max-width: none;
	overflow-y: auto;
	max-height: 100vh;

	.main-content {
	    max-width: 700px;
	    margin: 0 auto;
	    padding-top: 60px;
		text-align: center;
	}

	.text-section {
		margin-bottom: 30px;
	}

	.video-section {
		margin-bottom: 30px;

		video {
			width: 100%;
			border-radius: 10px;
		}

		div#ending-video {
		    width: 100%;
		    height: 0;
		    padding-top: 56.25%;

			.vjs-big-play-button {
				@extend .center-middle;
			}
		}
	}

	.button-section {
		.primary-btn {
			box-shadow: none;
		}
	}
}
