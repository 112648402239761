.page-background {
	width: 100vw;
	height: 100vh;
	background-size: cover;
	background-position: center center;
	position: absolute;
	z-index: -1;

	.overlay {
		@extend .full-parent;
	}

	.overlay-green {
		background: rgb(64,181,42);
		background: linear-gradient(132deg, rgba(64,181,42,1) 0%, rgba(5,52,13,1) 100%);
		opacity: 0.6;
	}

	.overlay-red {
		background: rgb(239,132,146);
		background: linear-gradient(132deg, rgba(239,132,146,1) 0%, rgba(164,31,31,1) 100%);
		opacity: 0.5;
	}

	.overlay-blue {
		background: rgb(42,108,181);
		background: linear-gradient(132deg, rgba(42,108,181,1) 0%, rgba(13,48,124,1) 100%);
		opacity: 0.95;
	}

	.overlay-full-opacity {
		opacity: 1;
	}
}
