.sidebar-milk {
	position: absolute;
	width: 25%;
	max-width: 300px;
	left: 0;
	height: 100vh;
	background: rgb(42,108,181);
	background: linear-gradient(132deg, rgba(42,108,181,1) 0%, rgba(13,41,101,1) 100%);
	border-radius: 0 30px 30px 0;

	@include media-query('900px'){
		display: none;
	};

	@include media-query-height('wp-phone'){
	    display: none;
	};

	.bottle-container {
		width: 200px;
		@extend .center-vertical;
		position: relative;
		margin: 0 auto;
		margin-top: 10vh;

		@include media-query-height('720px'){
			margin-top: 15vh;
		};

		.score-container {
			width: 100%;
			min-height: 390px;
			border-radius: 0 0 40px 40px;
			border: 6px solid white;
			border-top: none;

			.top-bottle {
				position: absolute;
				width: 100%;
				top: -110px;
				left: -0.5px;

				img {
					width: 100%;
					display: block;
				}
			}

			.inner-milk-container {
				height: 100%;
				width: calc(100% - 6px);
				position: absolute;
				bottom: 0;
				border-radius: 0 0 40px 40px;
				overflow: hidden;
			}

			.inner-milk-overflow {
				height: 96.5%;
				width: 100%;
				position: absolute;
				bottom: 0;
				border-radius: 0 0 40px 40px;
			}

			.inner-mlik {
				width: 100%;
				position: absolute;
				bottom: 0;
				background-color: white;
				border-radius: 0 0 40px 40px;

				img {
					width: 100%;
					position: absolute;
					top: -18px;
					left: -3px;
					display: block;
				}
			}

			.total-score {
				text-align: center;
				width: 100%;
				margin-bottom: 20px;
				position: relative;
				top: -40px;
				color: #6AB3EC;
				h1 {
					font-weight: 700;
					font-size: 6rem;
					margin: 0;
				}
				span {
				    font-size: 1.2rem;
				}
			}

			ul.score-list {
				width: 100%;
				padding-left: 0;
			    font-size: 1.6rem;
				margin: 0;
				position: relative;
				color: #198DE9;

				li.score-item {
					display: block;
					margin-bottom: 30px;
					position: relative;
					width: 100%;
					padding: 0 20px;
					padding-left: 50px;

					.icon {
						$local-size: 2.4rem;
						$local-img-size: 1.4rem;
						position: absolute;
						top: 50%;
						left: 15px;
						transform: translateY(-50%);
						height: $local-size;
						width: $local-size;
						display: flex;
						align-items: center;
						justify-content: center;
						border-radius: 50%;
						background-color: #ffffff;

						img {
							height: $local-img-size;
							width: $local-img-size;
							object-fit: contain;
							object-position: center;
						}
					}

					span {
						position: absolute;
						right: 20px;
					}

					&:last-child {
						// margin-bottom: 0;
					}

					@include media-query-height('720px'){
					    margin-bottom: 15px;
					};
				}
			}
		}
	}
}
