// popup
.popup-overlay {
    background: rgba(0,0,0,0.5);
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	width: 100%;
	height: 100%;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	display: inline-block;
    z-index: 99;

	&.videopopup-overlay {

		.content {
			display: flex;
			height: auto;
			max-height: calc(100vh - 60px);
			width: calc(100vw - 60px);
			max-width: none;

			.video-js {
				width: 100%;
				max-width: 100%;
				height: auto;
				max-height: 100vh;

				.vjs-big-play-button {
					@extend .center-middle;
				}
			}
		}

		video {
			position: relative;
			width: 100%;
			height: 100%;
			max-height: calc(100vh - 60px);
			object-fit: contain;
		}

		.popup-close {
			$local-size: 50px;
			$local-img-size: $local-size * 30 / 100;
			position: absolute;
			right: 0;
			top: 0;
			height: $local-size;
			width: $local-size;
			transform: translate(50%, -50%);
			color: white;
			border-radius: 50%;
			background: $color-main;

			img {
				position: absolute;
				top: 50%;
				left: 50%;
				transform:translate(-50%, -50%);
				height: $local-img-size;
				width: $local-img-size;
			}
		}
	}

	&.menupopup-overlay {

		.content {
			width: 100%;
			height: 100%;
			max-width: 100%;
			background: rgb(42,108,181);
			background: linear-gradient(132deg, rgba(42,108,181,1) 0%, rgba(8,37,101,0.8) 100%);
		}

		.menu-container{
			max-width: 400px;
			margin: 0 auto;
			position: relative;
			@extend .center-vertical;
		    padding: 20px;

			@include media-query('phone-wide'){
				top: auto;
			    transform: translateY(20px);
			};

		}

		.menu-logo {
		    margin-bottom: 60px;
			img{
				display: inline-block;
				height: 50px;
				margin: 0 auto;
			}
		}

		ul.menus {
			padding-left: 0;
		}


		li.menu-item {
			$local-size: 2.2rem;
			display: block;
		    font-size: $local-size;
		    font-weight: 500;
		    margin-bottom: 30px;

			a {
				display: flex;
				align-items: center;
				color: white;
				text-decoration: none;

				&:hover {
					opacity: 0.8;
				}
			}

			img {
				height: $local-size;
				width: $local-size;
				margin-right: 25px;
			}

		}

		form {
			select {
				padding: 8px 10px;
			    background: none;
			    font-size: 1.6rem;
			    border-radius: 3px;
			    padding-bottom: 10px;
			    border: 1px solid rgba(255,255,255,0.3);

				option {
					color: black;
				}
			}
		}

		.popup-close {
			$local-size: 60px;
			$local-img-size: $local-size * 40 / 100;
			height: $local-size;
			width: $local-size;
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				height: $local-img-size;
				width: $local-img-size;
				object-fit: contain;
				object-position: center;
			}
		}

	}

	&.scorepopup-overlay {

		.content {
			background: rgb(42,108,181);
			background: linear-gradient(132deg, rgba(42,108,181,1) 0%, rgba(13,41,101,1) 100%);
			border-radius: 20px;
			height: 90%;

			@include media-query('tablet'){
			    height: 62%;
			};

			@include media-query('tablet-small'){
			    height: 90%;
			};
		}

		.content-inner {
			max-height: 100%;
			width: 100%;
			overflow-y: auto;
			padding: 20px;
		}

		// Score Popup
		.popup-close {
			$local-size: 50px;
			$local-img-size: $local-size * 30 / 100;
			position: absolute;
			height: $local-size;
			width: $local-size;
			top: -20px;
			right: -10px;
			border-radius: 50%;
			color: white;
			background: $color-main;

			img {
				position: absolute;
				top: 50%;
				left: 50%;
				transform:translate(-50%, -50%);
				height: $local-img-size;
				width: $local-img-size;
			}
		}

		.milk-section {
			position: relative;
			max-width: 200px;
		    margin: 0 auto;
			margin-top: 50px;
			transform: scale(0.8);

			.bottle-container {
				width: 200px;

				.score-container {
					width: 100%;
					border-radius: 0 0 40px 40px;
					border: 6px solid white;
					border-top: none;
				    min-height: 385px;

					.top-bottle {
						position: absolute;
						width: 100%;
						top: -110px;
						left: -0.5px;

						img {
							width: 100%;
							display: block;
						}
					}

					.inner-milk-container {
						height: 100%;
						width: calc(100% - 2px);
						position: absolute;
						bottom: 0;
						border-radius: 0 0 40px 40px;
						overflow: hidden;
						left: 0;
					}

					.inner-milk-overflow {
						height: 96.5%;
						width: 100%;
						position: absolute;
						bottom: 0;
						border-radius: 0 0 40px 40px;
					}

					.inner-mlik {
						width: 100%;
						position: absolute;
						bottom: 0;
						background-color: white;
						border-radius: 0 0 40px 40px;

						img {
							width: 100%;
							position: absolute;
							top: -18px;
							left: -3px;
							display: block;
						}
					}

					.total-score {
						text-align: center;
						width: 100%;
						margin-bottom: 20px;
						position: relative;
						top: -40px;
						color: #6AB3EC;
						h1 {
							font-weight: 700;
							font-size: 6rem;
							margin: 0;
						}
						span {
						    font-size: 1.2rem;
						}
					}

					ul.score-list {
						width: 100%;
						padding-left: 0;
					    font-size: 1.6rem;
						margin: 0;
						position: relative;
						top: -30px;
						color: #198DE9;

						li.score-item {
							display: block;
							margin-bottom: 15px;
							position: relative;
							width: 100%;
							padding: 0 20px;
							padding-left: 50px;
							
							.icon {
								$local-size: 2rem;
								$local-img-size: 1.4rem;
								position: absolute;
								top: 50%;
								left: 20px;
								transform: translateY(-50%);
								height: $local-size;
								width: $local-size;
								display: flex;
								align-items: center;
								justify-content: center;
								border-radius: 50%;
								background-color: #fff;

								img {
									height: $local-img-size;
									width: $local-img-size;
									object-fit: contain;
									object-position: center;
								}
							}

							span {
								position: absolute;
								right: 20px;
							}

							&:last-child {
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}

		.business-score-container {
			float: right;
			width: 100%;
			padding: 10px;
			border: 2px solid rgba(255,255,255,0.1);
			border-radius: 20px;

			.business-title {
				margin-bottom: 15px;
				
				p {
					font-size: 1.4rem;
					margin: 0;
				}

				a {
					$local-size: 20px;
					$local-img-size: $local-size * 80 / 100;
					width: $local-size;
					height: $local-size;
					border-radius: 50%;
					background: rgba(255,255,255,0.1);
					display: inline-block;
					position: relative;
					top: 5px;
					margin-left: 5px;
					font-size: 1rem;
					color: white;

					img {
						position: absolute;
						height: $local-img-size;
						width: $local-img-size;
						@extend .center-middle;
					}
				}
			}

			.scores {
				@include clearfix;
				width: 100%;
			}

			.score-item {
				float: left;
				@include clearfix;
				width: 30%;
				margin-right: 3%;
			    margin-bottom: 15px;

				&:last-child,
				&:nth-child(3) {
					margin-right: 0;
				}

				@include media-query('phone-wide'){
				    width: 45%;
					margin-right: 5%;

					&:last-child,
					&:nth-child(3) {
						margin-right: 5%;
					}
				};

				.icon {
					float: left;
					width: 22px;
					height: 22px;
					border-radius: 100px;
					position: relative;
					background: rgba(255,255,255,0.1);
					display: inline-block;
					margin-right: 10px;

					img {
						$local-size: 1.4rem;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						height: $local-size;
						width: $local-size;
						object-fit: contain;
						object-position: center;

						/* Font Awesome
						color: $color-main;
						margin-right: 12px;
						font-size: 1.4rem;
						*/
					}
				}
			}

			.score-detail {
				float: left;
				width: calc(100% - 40px);

				p {
					margin: 0;
					font-size: 1.2rem;
					margin-bottom: 3px;
				}

				.score-bar {
					height: 3px;
					width: 100%;
					border-radius: 50px;
					background: #003967;
					position: relative;

					.score-bar-inner {
						height: 3px;
						position: absolute;
						left: 0;
						border-radius: 20px;

						&.green {
							background-color: $color-main;
						}

						&.orange {
							background-color: #E4A03B;
						}

						&.red {
							background-color: $red;
						}
					}
				}
			}
		}
	}

	&.answerpopup-overlay {

		.content {
			background: none;
			@include clearfix;

			@include media-query('phone-wide'){
				height: 90%;
			};
		}

		.avatar {
			width: 100px;
			height: 100px;
			border-radius: 100px;
			border: 4px solid white;
			position: absolute;
			background-size: cover;
			@extend .center-horizontal;

			&::after {
				content: "";
				display: inline-block;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 7.5px 10px 7.5px;
				border-color: transparent transparent #ffffff transparent;
				position: absolute;
				bottom: -17px;
				@extend .center-horizontal;
			}
		}

		.content-inner {
			width: 100%;
			background: #F8F9FB;
			color: $color-text;
			position: relative;
			top: 110px;
			font-size: 1.6rem;
			@include clearfix;
			overflow: hidden;
			overflow-y: auto;
		    border-radius: 50px;
			max-height: calc(100% - 110px);

			.score-section {
				width: 30%;
				padding: 30px 20px;
				float: left;

				@include media-query('tablet'){
					width: 40%;
				};

				@include media-query('wp-phone'){
					width: 100%;
				};

				.score-section-inner {
					// position: relative;
					// @extend .center-vertical;
				}

				.result-point {
					text-align: center;
					padding-bottom: 20px;
					border-bottom: 2px dashed #e8e8e8;
					margin-bottom: 30px;

					h1 {
						margin: 0;
						font-size: 6rem;
						font-weight: 700;
					}
					span {
						display: block;
						color: $color-text-gray;
					}
				}

				ul.score-list {
					width: 100%;
					padding-left: 0;
				    font-size: 1.6rem;
					margin: 0;
					position: relative;
					color: $color-text;
					margin-bottom: 40px;

					li.score-item {
						display: block;
						margin-bottom: 20px;
						position: relative;
						width: 100%;
						padding: 0 20px;
					    padding-left: 50px;

						img {
							$local-size: 1.4rem;
							position: absolute;
							top: 5px;
							left: 20px;
							height: $local-size;
							width: $local-size;
							object-fit: contain;
							object-position: center;

							/* Font Awesome
							color: $color-main;
							margin-right: 12px;
							font-size: 1.4rem;
							*/
						}

						span {
							position: absolute;
							right: 20px;
							color: #198DE9;
						}

						&:last-child {
							margin-bottom: 0;
						}

						@include media-query-height('720px'){
						    margin-bottom: 15px;
						};
					}
				}

				.answer-status {
					text-align: center;
					
					img {
						margin: auto;
					}

					.item-wrong,
					.item-correct {
						display: none;
					}

					&.wrong {
						.item-wrong {
							display: block;
						}
					}

					&.correct {
						.item-correct {
							display: block;
						}
					}

					.icon {
						.item-wrong {
							color: #F35A5A;
							font-size: 8rem;
						}
						.item-correct {
							color: $color-main;
							font-size: 8rem;
						}
					}
					p {
						color: $color-text;
						margin-top: 5px;
						margin-bottom: 20px;
					}
				}

				.button-section {
					text-align: center;
					.primary-btn {
						box-shadow: none;
						width: 90%;
					}
				}
			}

			.explanation-section {

				float: left;
				width: 70%;
				padding: 30px 20px;
				background: white;
			    line-height: 2.2rem;

				@include media-query('tablet'){
					width: 60%;
				};

				@include media-query('wp-phone'){
					width: 100%;
				};

				.explanation-detail {
					margin-bottom: 30px;
				}

				.correct,
				.explanation {
					margin-bottom: 20px;

					span {
						font-size: 1.4rem;
						color: $color-text-gray;
					}

					p {
						margin: 10px 0;
					}

				}

				.detail-item {
					margin-bottom: 10px;
					position: relative;

					.icon {
						width: 10px;
						height: 10px;
						border-radius: 10px;
						background: black;
						position: absolute;
						left: 10px;
					    top: 10px;

						&:before {
							content: '';
							position: absolute;
							@extend .center-middle;
							width: 27px;
							height: 27px;
							border-radius: 30px;
							opacity: 0.2;
							background: black;
						}

						&.icon-red {
							background: #E9444A;

							&:before {
								background: #E9444A;
							}
						}

						&.icon-green {
							background: $color-main;

							&:before {
								background: $color-main;
							}
						}
					}

					p {
						margin: 0;
						padding-left: 45px;
						color: $color-text-gray;
					}
				}

				.link-section {

					a {
						text-decoration: none;
						font-weight: 700;
						color: $color-text-secondary;
					}

					i {
						font-size: 1.4rem;
						margin-left: 10px;
					}
				}

				.button-section {
					text-align: center;
					margin-top: 20px;
					display: none;

					@include media-query('phone-wide'){
					    display: block;
					};

					a {
						color: $color-main;
						font-weight: 700;
						text-decoration: none;
					}
				}

			}


		}
	}

	&.generalpopup-overlay {

		.content {
			font-size: 1.6rem;
			background: none;

			.content-inner {
				background-color: white;
				position: absolute;
				@extend .center-vertical;
				padding: 20px;
				border-radius: 10px;
				text-align: center;
				max-height: 100%;
			    overflow-y: auto;
			}

			.popup-title {
				font-size: 2.4rem;
				font-weight: 700;
				color: $color-text-secondary;
				margin: 0;
				margin-bottom: 10px;
			}

			p {
				color: $color-text;
			}

			.button-section {
				text-align: center;
				margin-top: 20px;
				a {
					box-shadow: none;
				}
			}
		}
		
		.popup-close .icon img {
			@extend %set-img-icon;
			height: 14px;
			width: 14px;
		}
	}

	&.is-active {
		opacity: 1;
		visibility: visible;

		.content {
			opacity: 1;
			visibility: visible;
			top: 0;
		}

	}

	.content {
		background: #fff;
		position: absolute;
		top: -50%;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		width: 90%;
		max-width: 700px;
		height: 80%;
		opacity: 0;
		visibility: hidden;
		-webkit-transition: all 0.3s ease-in-out;
		-o-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
	}
}

.popup-close{
	position: absolute;
	right: 20px;
	top: 20px;
	font-size: 15px;
	color: #999;
	cursor: pointer;
	z-index: 999;
}
