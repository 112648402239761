.level-intro {
	.title-section {
		width: 100%;
		text-align: center;
		margin-bottom: 30px;

		@include media-query('phone-wide'){
			margin-bottom: 10px;
		};

		h1 {
			margin: 10px 0;
		}

		span {
			font-size: 1.4rem;
		}
	}

	.timeline-section {
	    width: 100%;
		position: relative;
		margin-bottom: 80px;

		@include media-query('phone-wide'){
			margin-bottom: 30px;
		};

		.line {
			width: 90%;
			border-top: 2px dashed rgba(255,255,255,0.3);
			position: absolute;
			@extend .center-middle;
		}

		.chapters {
			@include clearfix;

			.chapter-item {
			    width: 12.5%;
			    float: left;

				@include media-query('phone-wide'){
				    @include transform(scale(0.7));
				};

				&:first-child {
					.tooltip,
					.user-position {
						left: auto;
						transform: translate(0, 0);

						&::after {
							left: 25px;
						}
					}
				}

				&:last-child {
					.user-position {
						left: -4px;

						&:after {
							left: 75%;
						}
					}

					.tooltip {
						left: auto;
						transform: translateX(-200px);

						&::after {
							right: 10px;
							left: auto;
						}
					}
				}

				&.passed {
					.icon {
						background: $color-main;
					}
				}

				&.active {
					.user-position {
						visibility: visible;
						opacity: 1;
					}
				}
			}

			.icon {
				$local-size: 50px;
				// 50% of $local-size
				$local-img-size: $local-size * 50 / 100;
			    position: relative;
			    width: $local-size;
			    height: $local-size;
			    margin: 0 auto;
			    border-radius: 50%;
			    background: #676767;
			    box-shadow: 0 8px 20px rgba(0,0,0,0.2);
				@include transition(all ease-out 0.2s);

				img {
					position: absolute;
					height: $local-img-size;
					width: $local-img-size;
					@extend .center-middle;
					object-position: center;
					object-fit: contain;
				}

				&:hover {

					transform: scale(1.1);

					.tooltip {
						visibility: visible;
						opacity: 1;
						margin-bottom: 0px;
					}
				}

			}

			.user-position {
				visibility: hidden;
				opacity: 0;
				position: absolute;
				background: white;
				color: $color-text;
				top: 60px;
				border-radius: 15px;
				padding: 5px 10px;
				text-align: left;
				@extend .center-horizontal;
				box-shadow: 0 30px 30px -20px rgba(0,0,0,0.4);

				&::after {
					content: "";
				    display: inline-block;
				    width: 0;
				    height: 0;
				    border-style: solid;
				    border-width: 0 7.5px 10px 7.5px;
				    border-color: transparent transparent #ffffff transparent;
					position: absolute;
					top: -8px;
					@extend .center-horizontal;
				}

				h2 {
					margin: 0;
					color: $color-text-secondary;
					font-size: 1.8rem;
				}

				p {
					margin: 0;
					white-space: nowrap;
					font-weight: 700;
				}
			}

			.tooltip {
				visibility: hidden;
				opacity: 0;
				@include transition(all ease-out 0.2s);
				position: absolute;
				width: 250px;
				background: white;
				color: $color-text;
				top: 60px;
				border-radius: 15px;
				padding: 20px;
				text-align: left;
				@extend .center-horizontal;
				box-shadow: 0 30px 30px -20px rgba(0,0,0,0.4);
				margin-bottom: -20px;

				@include media-query('phone-wide'){
				    display: none;
				};

				&::after {
					content: "";
				    display: inline-block;
				    width: 0;
				    height: 0;
				    border-style: solid;
					border-width: 0 7.5px 10px 7.5px;
				    border-color: transparent transparent white transparent;
					position: absolute;
					top: -6px;
					@extend .center-horizontal;
				}

				h2 {
					margin: 0;
					color: $color-text-secondary;
					font-size: 1.8rem;
				}

				p {
					margin: 0;
					margin-top: 10px;
				}
			}
		}
	}

	.person-section {
		width: 100%;
		margin-bottom: 80px;

		@include media-query('phone-wide'){
			margin-bottom: 20px;
		};

		.avatar {
			width:100px;
			height: 100px;
			border-radius: 100px;
			border: 4px solid white;
			display: block;
			margin: 0 auto;
			background-size: cover;
			background-position: center center;
			margin-bottom: 15px;

			@include media-query('phone-wide'){
				width:70px;
				height: 70px;
			};
		}

		.bubble {
			padding: 20px;
			border-radius: 40px;
			background: white;
			max-width: 650px;
			position: relative;
			display: block;
			margin: 0 auto;
			color: $color-text;
			text-align: center;

			&::after {
				content: "";
				display: inline-block;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 7.5px 10px 7.5px;
				border-color: transparent transparent #ffffff transparent;
				position: absolute;
				top: -8px;
				@extend .center-horizontal;
			}
		}
	}

	.button-section {
		width: 100%;
		text-align: center;

		span{
			font-size: 1.4rem;
		}
	}
}
