.landingpage {
    text-align: center;
    position: absolute;
    // @extend .center-middle;
    width: 100%;
    overflow-y: auto;
    max-height: 100vh;
    max-width: none;

    @include media-query("phone") {
        padding-top: 50px;
    }

    .main-content {
        max-width: 1140px;
        margin: 0 auto;
        overflow-x: hidden;
    }

    .text-section {
        max-width: 600px;
        margin: 0 auto;
        margin-bottom: 60px;

        @include media-query("phone") {
            margin-bottom: 30px;
        }

        span {
            font-size: 1.4rem;
            opacity: 0.8;
        }
    }

    .video-section {
        position: relative;
        max-width: 500px;
        margin: 0 auto;
        margin-bottom: 60px;

        @include media-query("phone") {
            margin-bottom: 30px;
        }

        .video-frame {
            position: relative;
            background-color: #0a1009;
            width: 100%;
            padding-top: 45%;
            background-size: cover;
            background-position: center center;
            border-radius: 15px;
            box-shadow: 0 15px 50px -10px rgba(0, 0, 0, 0.3);
            margin-bottom: 15px;

            .videopopup-open {
                display: inline-block;
                font-size: 4.2rem;
                position: absolute;
                @extend .center-middle;

                a {
                    color: white;
                }
            }
        }
    }

    .timeline-section {
        width: 100%;
        position: relative;
        margin-bottom: 60px;

        @include media-query("phone-wide") {
            display: none;
        }

        .line {
            width: 90%;
            border-top: 2px dashed rgba(255, 255, 255, 0.3);
            position: absolute;
            @extend .center-middle;
        }

        .chapters {
            @include clearfix;

            .chapter-item {
                width: 12.5%;
                float: left;

                &:first-child,
                &:nth-child(2) {
                    .tooltip {
                        top: auto;
                        left: auto;
                        transform: translate(0, 0);

                        &::after {
                            left: 25px;
                        }
                    }
                }

                &:last-child,
                &:nth-child(7) {
                    .tooltip {
                        top: auto;
                        right: 250px;
                        transform: translate(-225px, 0);

                        &::after {
                            left: 225px;
                        }
                    }
                }
            }

            .icon {
                $local-size: 50px;
                $local-img-size: $local-size * 50 / 100; // 50% of $local-size
                width: $local-size;
                height: $local-size;
                margin: 0 auto;
                border-radius: 100px;
                background: $color-main;
                position: relative;
                box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
                @include transition(all ease-out 0.2s);

                img {
                    position: absolute;
                    height: $local-img-size;
                    width: $local-img-size;
                    @extend .center-middle;
                }

                &:hover {
                    transform: scale(1.1);

                    .tooltip {
                        visibility: visible;
                        opacity: 1;
                        margin-bottom: 0px;
                    }
                }
            }

            .tooltip {
                visibility: hidden;
                opacity: 0;
                @include transition(all ease-out 0.2s);
                position: absolute;
                width: 250px;
                background: white;
                color: $color-text;
                bottom: 60px;
                border-radius: 15px;
                padding: 20px;
                text-align: left;
                @extend .center-horizontal;
                box-shadow: 0 30px 30px -20px rgba(0, 0, 0, 0.4);
                margin-bottom: -20px;

                &::after {
                    content: "";
                    display: inline-block;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 7px 7.5px 0 7.5px;
                    border-color: #ffffff transparent transparent transparent;
                    position: absolute;
                    bottom: -6px;
                    @extend .center-horizontal;
                }

                h2 {
                    margin: 0;
                    color: $color-text-secondary;
                    font-size: 1.8rem;
                }

                p {
                    margin: 0;
                    margin-top: 10px;
                }
            }
        }
    }

    .button-section {
        // margin-bottom: 20px;

        a {
            margin: 5px;
        }
    }

    .how-to-play {
        margin-top: 10px;
        margin-bottom: 40px;
        padding: 4px 10px;
        text-decoration: underline;
        border-radius: 8px;
        box-shadow: none;
        background-color: transparent;
    }
}
