#header-site {
    position: absolute;
    padding: 20px;
    width: 100%;
    z-index: 9;

    .site-logo {
        display: inline-block;
        img {
            height: 50px;

            @include media-query("phone") {
                height: 40px;
            }
        }
    }
}

#header-question {
    position: absolute;
    padding: 20px;
    width: 25%;
    max-width: 300px;
    z-index: 9;
    @include clearfix;

    &.header-full {
        width: 100%;
        max-width: 100%;
    }

    @include media-query("tablet-wide") {
        z-index: 10;
    }

    @include media-query("900px") {
        width: 100%;
        max-width: 100%;
        background: rgb(42, 108, 181);
        background: linear-gradient(
            132deg,
            rgba(42, 108, 181, 1) 0%,
            rgba(13, 41, 101, 1) 100%
        );
        border-radius: 0 0 15px 15px;
        padding: 10px 20px;
    }

    @include media-query-height("wp-phone") {
        width: 100%;
        max-width: 100%;
        background: rgb(42, 108, 181);
        background: linear-gradient(
            132deg,
            rgba(42, 108, 181, 1) 0%,
            rgba(13, 41, 101, 1) 100%
        );
        border-radius: 0 0 15px 15px;
        padding: 10px 20px;
    }

    .site-logo {
        display: inline-block;
        img {
            height: 50px;

            @include media-query("900px") {
                height: 40px;
                position: relative;
                top: 5px;
            }
            @include media-query-height("wp-phone") {
                height: 40px;
                position: relative;
                top: 5px;
            }
        }
    }

    .btn-group {
        float: right;
        display: inline-block;
        @include clearfix;
        position: relative;
        z-index: 1;
    }

    .score-menu {
        border-radius: 15px;
        background: rgba(255, 255, 255, 0.1);
        float: right;
        text-decoration: none;
        text-align: center;
        margin-right: 10px;
        padding: 5px 8px;
        display: none;

        @include media-query("900px") {
            display: inline-block;
        }

        @include media-query-height("wp-phone") {
            display: inline-block;
        }

        h2 {
            color: white;
            margin: 0;
            font-weight: 500;
            font-size: 2.2rem;
        }

        span {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #7ba4e8;

            img {
                height: .9rem;
                width: .9rem;
                margin-left: .2em;
                object-fit: contain;
                object-position: center;
            }
        }
    }
}
